import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import {ApiUrl} from "../../../../auth/authMethods";
import SelectInput from "../../../../component/SelectInput";
import Select from "react-select";
import Box from "@mui/material/Box";
import CurrencyInput from "../../../../component/CurrencyInput";
import applyRegex from "../../../../utils/applyRegex";
import {NumericFormat} from "react-number-format";
import Typography from "@mui/material/Typography";
import {Switch} from "@mui/material";
import {InputLabelProps} from "../../../../utils/InputLabelProps";

export default function InputsOrder({
                                        setPedido,
                                        setPadraoParcelamentoId,
                                        setNeedInput
                                    }) {

    const bankList = [
        {value: 'BTG', label: 'BTG'},
        {value: 'BRADESCO', label: 'Bradesco'},
        {value: 'SANTANDER', label: 'Santander'},
        {value: 'ADYEN', label: 'Adyen'}
    ]

    const [numero, setNumero] = useState('');
    const [banco, setBanco] = useState('BTG')
    const [valorOriginal, setValorOriginal] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const [valor, setValor] = useState(0);
    const [observacao, setObservacao] = useState('');
    const [eventoId, setEventoId] = useState('');

    // taxas
    const [arrearsFee, setArrearsFee] = useState(1);
    const [penaltyFee, setPenaltyFee] = useState(2);
    const [taxaTotal, setTaxaTotal] = useState(3);

    // Parceiro comercial
    const [taxaSplit, setTaxaSplit] = useState(null);
    const [parceiroComercialId, setParceiroComercialId] = useState(null);

    const [hideParceiroComercial, setHideParceiroComercial] = useState(true);

    // Campo apenas para tela
    const [valorLiquido, setValorLiquido] = useState(0);

    const [eventoList, setEventoList] = useState([]);
    const [parceiroComercialList, setParceiroComercialList] = useState([]);

    useEffect(() => {
        setPedido({
            numero,
            banco,
            valorOriginal,
            valor,
            desconto,
            observacao,
            eventoId,
            arrearsFee,
            penaltyFee,
            taxaTotal,
            taxaSplit,
            parceiroComercialId
        })
    }, [
        numero, banco, valorOriginal, valor, desconto,
        observacao, eventoId, arrearsFee, penaltyFee,
        taxaTotal, taxaSplit, parceiroComercialId
    ])

    useEffect(() => {
        setValor(valorOriginal - (desconto ? desconto : 0))
        setValorLiquido(valorOriginal - (desconto ? desconto : 0))
    }, [valorOriginal, desconto])

    useEffect(() => {
        if (hideParceiroComercial) {
            setTaxaSplit(null)
            setParceiroComercialId(null)
        }
    }, [hideParceiroComercial])

    const handleChangeValue = (e, set) => {
        set(e.floatValue)
    }

    const handleChangeNumber = (set, value) => {
        const intValue = parseInt(applyRegex(value.replace('e', ''), /\d+/))
        set(intValue ? intValue : '')
    }

    const setEvento = (evento) => {
        setEventoId(evento.id)
        if (!!(evento?.padraoParcelamento)) {
            setPadraoParcelamentoId(evento.padraoParcelamento.id)
            setNeedInput(evento.padraoParcelamento.entrada === 'NAO_INCLUSA_PARCELAS')
        }
    }

    useEffect(() => {
        if (parceiroComercialId) {

            const parceito = parceiroComercialList.find(item =>
                item.id === parceiroComercialId
            )
            console.log(parceito)
            setTaxaTotal(parceito.participante.parceiroComercial.taxaTotal)
            setTaxaSplit(parceito.participante.parceiroComercial.taxaSplit)
        }
    }, [parceiroComercialId])

    return (
        <>
            <SelectInput
                url={ApiUrl + '/evento/list?status=ABERTO&size=20000'}
                list={eventoList}
                set={setEventoList}
                defaultValue={eventoId}
                setObj={setEvento}
                returnList={() => {
                    let returnList = []
                    eventoList.forEach((item) => {
                        returnList.push({value: item, label: `${item.nomeEvento}`})
                    })
                    return returnList
                }}
                placeholder={"Evento *"}
                required={true}
                fullWidth
            />
            <TextField
                label="Número do Lote"
                className='mt-2'
                value={numero}
                inputProps={{
                    maxLength: 30,
                }}
                onChange={(e) => setNumero(e.target.value)}
                required
                InputLabelProps={InputLabelProps}
                fullWidth
                autoFocus
            />
            <Select
                className="basic-single mb-4"
                classNamePrefix="select"
                id="form_select"
                required={true}
                defaultValue={banco}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                options={bankList}
                onChange={(e) => setBanco(e.value)}
                placeholder={'Banco *'}
            />
            <CurrencyInput
                label="Valor Original"
                customInput={TextField}
                prefix='R$'
                value={valorOriginal}
                inputProps={{
                    min: 1,
                    step: ".01"
                }}
                onValueChange={(e) => handleChangeValue(e, setValorOriginal)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />
            <CurrencyInput
                label="Desconto"
                value={desconto}
                inputProps={{
                    min: 0,
                    step: ".01"
                }}
                onValueChange={(e) => handleChangeValue(e, setDesconto)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />
            <CurrencyInput
                label="Valor Líquido das Parcelas"
                value={valor}
                inputProps={{
                    readOnly: true,
                    min: 1,
                    step: ".01"
                }}
                onValueChange={(e) => handleChangeValue(e, setValor)}
                InputLabelProps={InputLabelProps}
                required
                fullWidth
            />
            <TextField
                label="Observação"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                inputProps={{
                    maxLength: 100,
                }}
                fullWidth
            />

            <Box mt={2} component={'h5'}>
                Taxas
            </Box>
            <hr/>

            <NumericFormat
                customInput={TextField}
                thousandSeparator="."
                decimalSeparator=","
                suffix=' %'
                decimalScale={2}
                label="Taxa por atraso (%)"
                max='999999'
                inputProps={{
                    min: 1,
                    max: 10,
                    maxLength: 5,
                    step: ".01"
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0,
                    },
                }}
                value={penaltyFee}
                onValueChange={(e) => handleChangeValue(e, setPenaltyFee)}
                required
                fullWidth
            />

            <NumericFormat
                customInput={TextField}
                thousandSeparator="."
                decimalSeparator=","
                suffix=' %'
                decimalScale={2}
                label="Taxa de juros por mês (%)"
                max='999999'
                inputProps={{
                    min: 1,
                    max: 10,
                    maxLength: 5,
                    step: ".01"
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0,
                    },
                }}
                value={arrearsFee}
                onValueChange={(e) => handleChangeValue(e, setArrearsFee)}
                required
                fullWidth
            />


            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant="body1">Adicionar parceiro comercial:</Typography>
                <Switch
                    checked={!hideParceiroComercial}
                    onChange={() => setHideParceiroComercial(prevState => !prevState)}
                    color="primary"
                    inputProps={{'aria-label': 'alternar para adicionar parceiro comercial'}}
                />
            </div>

            {!hideParceiroComercial && <>
                <SelectInput
                    url={ApiUrl + '/user/parceiros?size=20000'}
                    list={parceiroComercialList}
                    set={setParceiroComercialList}
                    defaultValue={parceiroComercialId}
                    setObj={setParceiroComercialId}
                    returnList={() => {
                        let returnList = []
                        parceiroComercialList.forEach((item) => {
                            returnList.push({value: item.id, label: `${item.participante.nome}`})
                        })
                        return returnList
                    }}
                    placeholder={"Parceiro Comercial *"}
                    required={true}
                    fullWidth
                />

                <NumericFormat
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix=' %'
                    decimalScale={2}
                    label="Taxa de Split (%)"
                    max='999999'
                    inputProps={{
                        min: 1,
                        max: 10,
                        maxLength: 5,
                        step: ".01"
                    }}
                    InputLabelProps={{
                        style: {
                            zIndex: 0,
                        },
                    }}
                    value={taxaSplit}
                    onValueChange={(e) => handleChangeValue(e, setTaxaSplit)}
                    required
                    fullWidth
                />
            </>}

            <NumericFormat
                customInput={TextField}
                thousandSeparator="."
                decimalSeparator=","
                suffix=' %'
                decimalScale={2}
                label="Taxa de Administração (%)"
                max='999999'
                inputProps={{
                    min: 1,
                    max: 10,
                    maxLength: 5,
                    step: ".01"
                }}
                InputLabelProps={{
                    style: {
                        zIndex: 0,
                    },
                }}
                value={taxaTotal}
                onValueChange={(e) => handleChangeValue(e, setTaxaTotal)}
                required
                fullWidth
            />
        </>
    );
}