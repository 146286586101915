import ContentContainer from "../../component/ContentContainer";
import {useUser} from "../../auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import Orders from "../Order/Orders";
import InstitutionalHomePage from "./InstitutionalHomePage";
import CommonUserHome from "./CommonUserHome";


export default function HomePage() {

    const {user} = useUser()
    const navigate = useNavigate()

    // useEffect(() => {
    //     if (!(user?.token))
    //         navigate('/login')
    // }, [])

    return user?.token ?
        user?.isAdmin ?
            <ContentContainer width='xl'>
                <Orders/>
            </ContentContainer> :
            <CommonUserHome/> :
        <InstitutionalHomePage/>
}