import './CommonUserHome.css'
import {numberWithPoints} from "../../utils/numberWithPoints";
import {useEffect, useState} from "react";
import BasicLoader from "../../component/BasicLoader/BasicLoader";
import axios from "axios";
import {useUser} from "../../auth/AuthProvider";
import {ApiUrl} from "../../auth/authMethods";

export default function CommonUserHome() {

    const {user} = useUser()

    const [loadingData, setLoadingData] = useState(true)
    const [data, setData] = useState(true)

    // const data = [
    //     1200,
    //     -1200,
    //     1348.22,
    //     -3200.50,
    // ]

    useEffect(() => {
        if (loadingData) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user?.token}`;

            axios.get(`${ApiUrl}/reports/${user?.id}`)
                .then(function (response) {
                    console.log("searching data")
                    setData(response.data)
                    setLoadingData(false)
                }).catch(function (errorReceived) {
                console.log(errorReceived);
            });
        }
    }, [loadingData]);

    return <div className='home-participante'>
        <h2 className='titulo-recebiveis'>Lançamentos futuros</h2>
        <div className="div-semana">
            {loadingData?
                <BasicLoader height={25}/>:
                <>
                    <div id="div-hoje" className='div-card-data'>
                        <h6>Hoje</h6>
                        <hr/>
                        {data[0].itens.length > 0?
                            <>
                                <p className='p-lista-leiloes'>
                                    {data[0].itens.map(item =>
                                            <p>
                                                {item.nomeEvento} &nbsp;
                                                <span className={item.valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                        R${numberWithPoints(item.valor)}
                                    </span>
                                            </p>
                                    )}
                                </p>
                                <hr/>
                                <p className={data[0].valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                    R${numberWithPoints(data[0].valor)}
                                </p>
                            </>:
                            <>
                                * Não há lançamentos agendados até o momento.
                            </>
                        }

                    </div>
                    <div className="div-card-data">
                        <h6>Amanhã</h6>
                        <hr/>
                        {data[1].itens.length > 0?
                            <>
                                <p className='p-lista-leiloes'>
                                    {data[1].itens.map(item =>
                                            <p>
                                                {item.nomeEvento} &nbsp;
                                                <span className={item.valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                        R${numberWithPoints(item.valor)}
                                    </span>
                                            </p>
                                    )}
                                </p>
                                <hr/>
                                <p className={data[1].valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                    R${numberWithPoints(data[1].valor)}
                                </p>
                            </>:
                            <>
                                * Não há lançamentos agendados até o momento.
                            </>
                        }
                    </div>
                    <div className="div-card-data">
                        <h6>Dia subsequente</h6>
                        <hr/>
                        {data[2].itens.length > 0?
                            <>
                                <p className='p-lista-leiloes'>
                                    {data[2].itens.map(item =>
                                            <p>
                                                {item.nomeEvento} &nbsp;
                                                <span className={item.valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                        R${numberWithPoints(item.valor)}
                                    </span>
                                            </p>
                                    )}
                                </p>
                                <hr/>
                                <p className={data[2].valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                    R${numberWithPoints(data[2].valor)}
                                </p>
                            </>:
                            <>
                                * Não há lançamentos agendados até o momento.
                            </>
                        }
                    </div>
                    <div className="div-mes div-card-data">
                        <h6>Próximos 30 dias</h6>
                        <hr/>
                        {data[3].itens.length > 0?
                            <>
                                <p className='p-lista-leiloes'>
                                    {data[3].itens.map(item =>
                                            <p>
                                                {item.nomeEvento} &nbsp;
                                                <span className={item.valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                        R${numberWithPoints(item.valor)}
                                    </span>
                                            </p>
                                    )}
                                </p>
                                <hr/>
                                <p className={data[3].valor >= 0 ? 'valor-positivo' : 'valor-negativo'}>
                                    R${numberWithPoints(data[3].valor)}
                                </p>
                            </>:
                            <>
                                * Não há lançamentos agendados até o momento.
                            </>
                        }
                    </div>
                </>
            }
        </div>

    </div>
}