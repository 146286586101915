import TextField from "@mui/material/TextField";
import Select from "react-select";
import React, {useState} from "react";
import {ApiUrl} from "../../../auth/authMethods";
import SelectInput from "../../../component/SelectInput";
import {numberWithPoints} from "../../../utils/numberWithPoints";
import {genderList, quantityList} from "./lists";
import applyRegex from "../../../utils/applyRegex";

export default function InputsAnimal({
                                         numeroLote, setNumeroLote,
                                         nomeAnimal, setNomeAnimal,
                                         generoAnimal, setGeneroAnimal,
                                         quantidade, setQuantidade,
                                         status, setStatus,
                                         pedidoId, setPedidoId,
                                         observacao, setObservacao,
                                         errorMessage, setErrorMessage,
                                         errorColumn, setErrorColumn
                                     }) {

    const [pedidosList, setPedidosList] = useState([]);

    const handleToggleGender = (e) => {
        setGeneroAnimal(generoAnimal === 'Macho' ? 'Fêmea' : 'Macho')
    }

    const handleChangeNumber = (set, value) => {
        const intValue = parseInt(applyRegex(value.replace('e', ''), /\d+/))
        set(intValue? intValue : '')
    }

    return <>
        <TextField
            label="Número do Lote"
            required={true}
            inputProps={{
                min: 1
            }}
            errortext="Campo obrigatório!"
            type='text'
            value={numeroLote}
            onChange={(e) => handleChangeNumber(setNumeroLote, e.target.value)}
            fullWidth
            autoFocus
        />
        <TextField
            label="Nome do Animal"
            required={true}
            inputProps={{
                maxLength: 255
            }}
            errortext="Campo obrigatório!"
            value={nomeAnimal}
            onChange={(e) => setNomeAnimal(e.target.value)}
            fullWidth
        />
        <TextField
            label="Observação"
            inputProps={{
                maxLength: 255
            }}
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
            fullWidth
            InputLabelProps={{
                style: {
                    zIndex: 0,
                },
            }}
        />
        <Select
            className="basic-single mb-4"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={genderList.find(gender => gender.value === generoAnimal)}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={genderList}
            onChange={(e) => setGeneroAnimal(e.value)}
            placeholder={'Gênero *'}
        />
        <Select
            className="basic-single mb-4"
            classNamePrefix="select"
            id="form_select"
            required={true}
            defaultValue={quantityList.find(qtt => qtt.value === quantidade)}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={true}
            options={quantityList}
            onChange={(e) => setQuantidade(e.value)}
            placeholder={'Quantidade *'}
        />
        <SelectInput
            url={ApiUrl + '/pedidos/list?size=20000'}
            list={pedidosList}
            set={setPedidosList}
            defaultValue={pedidoId}
            setObj={setPedidoId}
            returnList={() => {
                let returnList = []
                pedidosList.forEach((item) => {
                    returnList.push({
                        value: item.id,
                        label: `${item.evento.nomeEvento} - lote ${item.numero}`
                    })
                })
                return returnList
            }}
            placeholder={"Lote *"}
            required={true}
            fullWidth
        />
    </>
}