import React, {useEffect, useState} from "react";
import OrderList from "./Components/OrderList";
import PaginationControl from "../../component/Pagination/PaginationControl";
import PopupAddOrder from "./Components/PopupAddOrder";
import {useParams} from "react-router-dom";
import {useUser} from "../../auth/AuthProvider";
import PopupExport from "./Components/PopupExport";
import {ApiUrl} from "../../auth/authMethods";
import PopupAddOrderV1 from "./Components/InputsAdd/PopupAddOrderV1";
import PopupAddOrderV2 from "./Components/InputsAddInstallmentPattern/PopupAddOrderV2";


export default function Orders() {

    const {user} = useUser()

    const {type} = useParams();

    const baseUrl = `${ApiUrl}/pedidos`

    const baseListUrl = baseUrl + '/list'
    const urlList = !type ?
        baseListUrl :
        type === 'sold' ?
            baseListUrl + '?papel=Vendedor' :
            baseListUrl + '?papel=Comprador'

    useEffect(() => {
        setLoadData(true)
    }, [type])


    const [loadData, setLoadData] = useState(true)

    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [sort, setSort] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [totalElements, setTotalElements] = useState(0)

    const [filter, setFilter] = useState({})

    return <>
        <h1>Lista de Lotes {(type === 'sold' && 'Vendidos') || (type && 'Comprados')}</h1>
        {user?.isAdmin && <div className='text-end button-show'>
            <b>Adicionar:&nbsp;&nbsp;</b>
            <PopupAddOrderV1 setLoadData={setLoadData}/> &nbsp;
            <PopupAddOrderV2 setLoadData={setLoadData}/>
        </div>}
        <OrderList
            baseUrl={urlList}
            loadData={loadData}
            setLoadData={setLoadData}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            sort={sort} setSort={setSort}
            filter={filter} setFilter={setFilter}
        />
        <PaginationControl
            totalElements={totalElements}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setLoadData={setLoadData}
        />
        {user?.isAdmin &&
            <PopupExport type={'list'} filter={filter}/>
        }
    </>
}