
const genderList = [
    {value: 'MACHO', label: 'Macho'},
    {value: 'FEMEA', label: 'Fêmea'},
    {value: 'BEZERRO', label: 'Bezerro'},
    {value: 'POTRO', label: 'Potro'},
    {value: 'EMBRIAO', label:'Embriao'},
    {value: 'ZIGOTO', label:'Zigoto'},
    {value: 'SEM_SEXO', label:'Sem sexo'},
    {value: 'OUTRO', label:'Outro'},
]

const quantityList = [
    {value: 'INDIVIDUAL', label: 'Individual'},
    {value: 'DUPLO', label: 'Duplo'},
    {value: 'TRIPLO', label: 'Triplo'},
    {value: 'QUADRUPLO', label: 'Quadruplo'}
]

export {quantityList, genderList}